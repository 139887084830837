const colors = {
  100: "warning",
  200: "success",
  201: "success",
  401: "warning",
  403: "info",
  404: "warning",
  409: "warning"
}

const messages = {
  error: {
    100: {
      title: "Atención",
      message: "No puedes modificar un partido que ya ha finalizado"
    },
    404: {
      title: "Error",
      message: "Elemento no encontrado."
    },
    500: {
      title: "Error interno",
      message: "Ha ocurrido un error, por favor recargue la página."
    }
  },
  login: {
    200: {
      title: "Bienvenido/a",
      message: "Es un gusto tenerte de regreso."
    },
    401: {
      title: "Contraseña incorrecta",
      message: "Contraseña inválida, intenta de nuevo."
    },
    402: {
      title: "Usuario no activo",
      message: "No puedes acceder porque tu usuario ha sido desactivado."
    },
    403: {
      title: "Demasiados intentos",
      message:
        "Hemos detectado demasiados intentos de inicio fallido, por favor intenta mas tarde."
    },
    404: {
      title: "Usuario no encontrado",
      message: "No hay un usuario registrado con el correo introducido."
    },
    400: {
      title: "Campos faltantes",
      message: "Ingresa todos los campos obligatorios."
    }
  },
  register: {
    200: {
      title: "¡Éxito!",
      message: "Registro completado con éxito, inicia sesión."
    },
    400: {
      title: "Error",
      message: "Faltan campos por llenar."
    },
    409: {
      title: "Registro duplicado",
      message: "Ya existe un usuario creado con el mismo correo o DNI."
    }
  },
  recovery: {
    200: {
      title: "Revisa tu correo",
      message: "Hemos enviado un correo con el código de verificación."
    },
    404: {
      title: "Usuario no encontrado",
      message: "No hay un usuario registrado con el correo introducido."
    }
  },
  master: {
    200: {
      title: "¡Éxito!",
      message: "Elemento agregado con éxito."
    },
    409: {
      title: "Elemento duplicado",
      message: "Ya existe un elemento con estos datos en el sistema."
    },
    400: {
      title: "Error",
      message: "Faltan campos por llenar."
    }
  },
  "master-update": {
    200: {
      title: "¡Éxito!",
      message: "Actualización realizada con éxito."
    },
    409: {
      title: "Elemento duplicado",
      message: "Ya existe un elemento con estos datos en el sistema."
    }
  },
  "master-status": {
    200: {
      title: "¡Éxito!",
      message: "Estado del elemento actualizado con éxito."
    }
  },
  "master-verify": {
    200: {
      title: "¡Éxito!",
      message: "El elemento fue verificado."
    }
  },
  "master-status-delete": {
    200: {
      title: "¡Éxito!",
      message: "El elemento fue eliminado."
    }
  },
  logout: {
    200: {
      title: "¡Hasta luego!",
      message: "Esperamos verte pronto por aquí."
    }
  },
  changePassword: {
    200: {
      title: "¡Éxito!",
      message: "Su contraseña ha sido cambiada con éxito."
    },
    401: {
      title: "Contraseña incorrecta",
      message: "Contraseña actual invalida, intente de nuevo."
    }
  },
  setPassword: {
    200: {
      title: "¡Éxito!",
      message: "Su contraseña ha sido restablecida con éxito."
    },
    404: {
      title: "Token inválido",
      message: "El token de recuperación es inválido, verifique su URL."
    },
    400: {
      title: "Token expirado",
      message: "El token de recuperación ya no está disponible."
    },
    403: {
      title: "Token no válido",
      message:
        "El token de recuperación de clave ya no es válido. Por favor inicia el proceso nuevamente"
    }
  },
  resend: {
    200: {
      title: "Correo enviado",
      message: "Tu correo de verificación ha sido enviado con éxito"
    },
    404: {
      title: "Usuario no encontrado",
      message: "No hay un usuario registrado con el correo introducido."
    }
  },

  notificationReaded: {
    200: {
      title: "Notificaciones leídas",
      message: "Todas las notificaciones han sido marcadas como leídas",
      color: "info"
    }
  },
  academy: {
    200: {
      title: "¡Éxito!",
      message: "La academia ha sido registrada"
    },
    400: {
      title: "Campos faltantes",
      message: "Ingresa todos los campos obligatorios."
    },
    409: {
      title: "Registro duplicado",
      message: "Ya existe una academia con el mismo nombre."
    }
  },
  player: {
    200: {
      title: "¡Éxito!",
      message: "El registro de jugador(a) fue completado"
    },
    400: {
      title: "Campos faltantes",
      message: "Ingresa todos los campos obligatorios."
    },
    404: {
      title: "Error",
      message: "No se encontró la academia del jugador"
    },
    409: {
      title: "Registro duplicado",
      message: "Ya existe un jugador registrado con esos datos"
    }
  },
  coach: {
    200: {
      title: "¡Éxito!",
      message: "El registro de entrenador(a) fue completado"
    },
    400: {
      title: "Campos faltantes",
      message: "Ingresa todos los campos obligatorios."
    },
    404: {
      title: "Error",
      message: "No se encontró la academia del entrenador"
    },
    409: {
      title: "Registro duplicado",
      message: "Ya existe un entrenador registrado con esos datos"
    }
  }
}

export const notifyMessage = (code = 0, type = "error") => {
  return {
    title: messages[type][code].title || messages["error"][0].title,
    message: messages[type][code].message || messages["error"][0].message,
    color: colors[code] || "warning"
  }
}
